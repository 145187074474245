@font-face {
  font-family: "SansterdamMedCond";
  src: url("./fonts/NueMedium/NueMedium.woff2") format("woff2"),
    url("./fonts/NueMedium/NueMedium.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
/* league-spartan-regular - latin */
@font-face {
  font-family: "league spartan";
  src: url("./fonts/leaguespartan/league-spartan-v11-latin-900.woff2")
    format("woff2");
  font-weight: 900;
  font-style: normal;
}
@import url("https://fonts.googleapis.com/css2?family=League+Spartan:wght@500&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Header.css */
.header {
  width: 100%;
  background: linear-gradient(to right, #6b09af, #aae0c0);
  font-family: "SansterdamMedCond", sans-serif;
  font-weight: 900;
}

.header-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
}

.logo {
  max-width: 100%;
  width: 200px;
  height: 80px;
  padding-right: 40px;
}
.header-logo {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.nav {
  flex-grow: 1;
  display: flex;
  justify-content: center;
}

.nav-list {
  list-style: none;
  display: flex;
  gap: 20px;
  padding: 0;
  margin: 0;
}

.nav-item .nav-link {
  text-decoration: none;
  font-size: 35px;
  color: white;
  transition: all;
}
.nav-item .nav-link:hover {
  transform: scale(1.05);
  color: white;
}

.nav-item .nav-link.active {
  color: white;
}
.contact-button {
  display: flex;
  align-items: center;
}

.contact-link {
  text-decoration: none;
  font-size: 35px;
  color: white;
  margin-left: auto;
  transition: all;
}
.hamburger {
  display: none;
  flex-direction: column;
  cursor: pointer;
  margin-left: auto;
}

.hamburger .bar {
  height: 4px;
  width: 25px;
  background-color: white;
  margin: 3px 0;
  transition: 0.4s;
}

@media (max-width: 768px) {
  .header-content {
    border-bottom: 1px solid grey;
    padding: 0px;
  }
  .nav {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.9);
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    transform: translateY(-100%);
    transition: transform 0.3s ease-in-out;
    z-index: 10;
  }

  .nav.open {
    transform: translateY(0);
  }

  .nav-list {
    flex-direction: column;
    gap: 10px;
  }

  .hamburger {
    display: flex;
  }

  .logo {
    width: 150px;
    height: 60px;
    /* padding-right: 0; */
  }
  .contact-link {
    display: none;
  }
}

/* Slider.css */
.slider-container {
  position: relative;
  height: 100vh;
  width: 100%;
  border: none;
  background-color: black;
}
/* .vector-img {
  position: absolute;
  bottom: -25px;
  left: 0;
  width: 100%;
  height: auto;
} */
.slider-six .vector-img {
  width: 100%;
  position: absolute;
  bottom: -1px;
  left: 0;
  transform: rotate(-180deg);
  z-index: 10;
  color: #fff;
}

.slick-next,
.slick-prev {
  display: none !important;
}
.slider-container .slide-wrapper {
  width: 100%;
  height: 100vh;
}
.slider-container .slide {
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
  background-position: center;
  opacity: 0.9;
  position: relative;
}
.slider-container .slide::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  z-index: 1;
}

.slide-content {
  text-align: center;
  color: white;
  z-index: 2;
}
.slide-content h2 {
  font-size: 80px;
  margin-bottom: 0.5rem;
  text-transform: capitalize;
  font-family: "League Spartan", sans-serif;
  font-weight: 900;
}

@media (max-width: 768px) {
  .slider-six .vector-img {
    display: none;
  }
  .slider-container {
    height: 80vh;
  }

  .slider-container .slide-wrapper {
    height: 80vh;
  }

  .slide-content h2 {
    font-size: 30px;
  }
}

@media (max-width: 480px) {
  .slider-container {
    height: 70vh;
  }

  .slider-container .slide-wrapper {
    height: 70vh;
  }

  .slide-content h2 {
    font-size: 20px;
  }
}

/* partners.css */
.partners {
  padding: 40px 0px;
  margin: 80px 0px;
}

.partner-heading {
  text-align: center;
  font-size: 80px;
  color: #6b09af;
  text-transform: capitalize;
  margin-bottom: 25px;
  font-family: "SansterdamMedCond", sans-serif;
  font-weight: 900;
}

.partners .row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}

.partners .col-md-4 {
  flex: 0 0 calc(33.3333% - 20px);
  max-width: calc(33.3333% - 20px);
  box-sizing: border-box;
  margin-bottom: 20px;
  margin: 5px;
  display: flex;
  justify-content: center;
}

.partner-image-div {
  width: 100%;
  max-width: 600px;
  height: 300px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}
.partner-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.partner-image:hover {
  transform: scale(1.05);
  cursor: pointer;
}
@media (max-width: 768px) {
  .partner-heading {
    font-size: 45px;
  }

  .partners .col-md-4 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .partner-image-div {
    height: 300px;
    overflow: hidden;
  }
}

@media (max-width: 480px) {
  .partners {
    margin: 60px 0px;
  }
  .partner-heading {
    font-size: 35px;
  }

  .partners .col-md-4 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .partner-image-div {
    height: 200px;
  }
}

/* service css */
.service {
  background: linear-gradient(to right, #6b09af, #aae0c0);
  height: 80vh;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
/* .service-banner {
  position: absolute;
  width: 100%;
  bottom: -5px;
  bottom: -7px;
  left: 0;
} */
.service-content h1 {
  color: white;
  text-align: center;
  text-transform: capitalize;
  margin-bottom: 100px;
  font-size: 90px;
  font-family: "SansterdamMedCond", sans-serif;
  font-weight: 900;
}
.home-services {
  padding: 40px 0px;
  margin: 60px 0px;
  text-align: center;
}

.home-service-heading {
  text-align: center;
  font-size: 80px;
  color: #6b09af;
  text-transform: capitalize;
  margin-bottom: 25px;
  font-family: "SansterdamMedCond", sans-serif;
  font-weight: 900;
}

.home-services .row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 15px;
  /* gap: 10px; */
}
.home-services .col-md-4 {
  text-align: center;
  margin-bottom: 20px;
  flex: 0 0 calc(33.3333% - 20px);
  max-width: calc(33.3333% - 20px);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}
.home-services .col-md-4:hover {
  transform: scale(1.05);
  cursor: pointer;
}
.home-service-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.home-service-img {
  width: 100%;
  max-width: 600px;
  max-height: 300px;
  /* max-width: 550px;
  max-height: 220px; */
  margin-bottom: 10px;
}
.home-service-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.home-service-content h4 {
  color: black;
  font-size: 30px;
  text-transform: capitalize;
  margin-top: 10px;
  font-family: "League Spartan", sans-serif;
  font-weight: 900;
}
.accordion-container {
  margin: 60px 0px;
  padding: 60px 0px;
  display: flex;
  gap: 20px;
}

.accordion-col {
  flex: 1;
  /* flex: 0 0 50%; */
}
.accordion-item {
  margin-bottom: 10px;
  overflow: hidden;
  transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg)
    rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
  transform-style: preserve-3d;
  transition: all 0.55s;
  box-shadow: 1px 1px 15px rgba(182, 182, 182, 0.4);
}
.accordion-item:hover {
  border-color: #f5e6ff;
  box-shadow: 1px 1px 15px #66169d;
}

.accordion-item.blurred {
  filter: blur(1px);
  opacity: 0.8;
}
.accordion-btn {
  background-color: white;
  border: none;
  cursor: pointer;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.accordion-btn h3 {
  margin: 0;
  font-size: 1.3em;
  font-family: "League Spartan", sans-serif;
  font-weight: 900;
}
.accordion-content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.55s ease-out, padding 0.55s ease-out;
  padding: 0 20px;
  background-color: white;
  font-family: "League Spartan", sans-serif;
  font-weight: 500;
}
.accordion-content.active {
  max-height: 500px;
  padding: 20px 20px 30px;
}
.accordion-content ul {
  list-style-type: none;
  padding-left: 20px;
  font-size: 16px;
}
.accordion-icon {
  margin-left: auto;
  font-size: 25px;
  color: #6b09af;
}

@media (max-width: 768px) {
  .service {
    height: 50vh;
    width: 100%;
  }

  .accordion-container {
    margin: 40px 0px;
  }
  .service-content h1 {
    font-size: 45px;
  }
  .accordion-col {
    flex: 0 0 100%;
  }
  .accordion-btn h3 {
    font-size: 20px;
  }
  .accordion-content ul {
    font-size: 15px;
  }
  .accordion-icon {
    font-size: 20px;
  }
  .home-services .col-md-4 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .home-service-heading {
    font-size: 40px;
  }
  .home-service-img {
    max-width: 100%;
    width: 100%;
    max-height: 400px;
    margin-bottom: 10px;
  }
  .home-services h3 {
    font-size: 30px;
  }
  .home-service-vector {
    display: none;
  }
}

@media (max-width: 480px) {
  .service {
    height: 50vh;
    width: 100%;
  }
  .service-content h1 {
    font-size: 40px;
  }
  .home-service-heading {
    font-size: 35px;
  }
  .home-services .col-md-4 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .home-service-img {
    max-width: 500px;
    max-height: 240px;
    margin-bottom: 10px;
  }
  .home-service-content h4 {
    font-size: 25px;
  }
  .accordion-container {
    margin: 40px 0px;
  }
  .service-content h1 {
    font-size: 35px;
  }
  .accordion-col {
    flex: 0 0 100%;
  }
  .accordion-item {
    box-shadow: 1px 1px 15px rgba(182, 182, 182, 0.4);
  }
  .accordion-btn h3 {
    font-size: 16px;
  }
  .accordion-content ul {
    font-size: 15px;
  }
  .accordion-icon {
    font-size: 20px;
  }
}

/* portfolio css */
@keyframes slideInOut {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

.portfolio {
  padding: 60px 0px;
  max-width: 1200px;
  margin: 0 auto;
  margin: 40px 0px;
  /* overflow: hidden; */
}

.portfolio iframe {
  border-radius: 20px;
  width: 100%;
  max-width: 350px;
}
@media (max-width: 1024px) {
  .portfolio iframe {
    height: 300px;
  }
}

@media (max-width: 600px) {
  .portfolio iframe {
    height: 250px;
    width: 100%;
    max-width: 145px;
    border-radius: 10px;
  }
}
/* aboutpage css */
.about {
  background: linear-gradient(to right, #6b09af, #aae0c0);
  height: 80vh;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.about-content {
  color: white;
  text-align: center;
  margin-bottom: 100px;
}
.about-content h1 {
  font-size: 90px;
  font-family: "SansterdamMedCond", sans-serif;
  font-weight: 900;
  text-transform: capitalize;
}

.about-section {
  padding: 30px 0;
  margin: 40px 0px;
}
.about-section .row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  /* gap: 5px; */
  margin: 50px 0;
}
.about-section .row.row-reverse {
  flex-direction: row-reverse;
}
.about-section .col-md-6 {
  flex: 0 0 48%;
  width: 48%;
  margin: 10px;
  margin-bottom: 20px;
}
.about-img-div {
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100%;
}
.about-img {
  width: 100%;
  height: 100%;
  /* height: auto; */
  border-radius: 5px;
  object-fit: cover;
  mix-blend-mode: luminosity;
  position: relative;
  z-index: 1;
}

.about-img-div::before {
  content: "";
  position: absolute;
  top: 20%;
  left: -150px;
  width: 387px;
  height: 387px;
  background-image: url("/public/images/Ellipse.png");
  background-size: contain;
  background-repeat: no-repeat;
  opacity: 0.1;
  z-index: 1;
}

.about-section-content h3 {
  font-size: 45px;
  line-height: 65px;
  color: black;
  text-transform: uppercase;
  margin-bottom: 0;
  font-family: "League Spartan", sans-serif;
  font-weight: 900;
}
.about-section-content p {
  font-size: 22px;
  margin: 30px 0 36px;
  font-family: "League Spartan", sans-serif;
  font-weight: 500;
}
.about-section-content ul {
  list-style-type: none;
  padding-left: 0;
  font-family: "League Spartan", sans-serif;
  font-weight: 500;
}

.about-section-content ul li {
  font-size: 20px;
  margin-bottom: 2px;
  position: relative;
}

@media (max-width: 768px) {
  .about {
    height: 50vh;
    width: 100%;
  }
  .about-content h1 {
    font-size: 40px;
  }

  .about-section .col-md-6 {
    flex: 0 0 100%;
    width: 100%;
    margin: 10px 0;
  }

  .about-section-content h3 {
    font-size: 35px;
  }

  .about-section-content p {
    font-size: 20px;
  }
  .about-section-content ul li {
    font-size: 20px;
  }
}
@media (max-width: 480px) {
  .about {
    height: 50vh;
    width: 100%;
  }

  .about-content h1 {
    font-size: 35px;
  }

  .about-section .col-md-6 {
    flex: 0 0 100%;
    width: 100%;
    margin: 10px 0;
  }

  .about-section-content h3 {
    font-size: 25px;
    font-weight: 600;
    margin: 0px;
  }

  .about-section-content p {
    font-size: 18px;
  }
  .about-section-content ul li {
    font-size: 16px;
  }
}

/* contactus css */
.contactus {
  padding: 60px 0;
  margin-top: 20px;
}

.contact-form {
  width: 100%;
  max-width: 700px;
  margin: 0 auto;
  padding: 40px 30px;
}
.contact-form .row {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin: 20px -10px;
}
.contact-form .col-md-6 {
  flex: 1;
  margin-bottom: 10px;
  padding: 0 10px;
}

.contact-form .col-md-12 {
  flex: 1 0 100%;
  margin-bottom: 10px;
  padding: 0 10px;
}
.contact-form input,
.contact-form textarea {
  background-color: #f4f5f8;
  width: 100%;
  padding: 25px 30px;
  border-radius: 10px;
  border: 1px solid rgb(237, 236, 236);
  margin-top: 5px;
  font-family: "League Spartan", sans-serif;
}

.contact-form input:focus,
.contact-form textarea:focus {
  outline: none;
  cursor: pointer;
}
.contact-form input::placeholder,
.contact-form textarea::placeholder {
  text-transform: capitalize;
  font-size: 16px;
  color: #686a6f;
}
.form-btn-div {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.form-btn {
  border: none;
  background: linear-gradient(to right, #6b09af, #aae0c0);
  border-radius: 10px;
  color: white;
  width: 230px;
  height: 70px;
  font-size: 18px;
  font-family: "League Spartan", sans-serif;
  font-weight: 900;
  letter-spacing: 1px;
  cursor: pointer;
  text-transform: uppercase;
}
.form-btn:hover {
  background-color: #6b09af;
  border: none;
}

@media (max-width: 768px) {
  .contact-form .col-md-6 {
    flex: 0 0 100%;
  }
}
@media (max-width: 480px) {
  .contact-form .col-md-6 {
    flex: 0 0 100%;
  }
}

/* footer ccss */
.footer {
  color: white;
  background: linear-gradient(to right, #6b09af, #aae0c0);
  padding: 40px 0px;
  margin-top: 60px;
}
.footer-link {
  text-decoration: none;
  font-size: 30px;
  color: white;
  font-family: "SansterdamMedCond", sans-serif;
  font-weight: 900;
  padding: 10px;
  transition: all;
  text-transform: capitalize;
}
.footer-link:hover {
  transform: scale(1.05);
}
.footer .row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}
.footer .col-md-4 {
  flex: 0 0 30%;
  max-width: 30%;
  margin-bottom: 20px;
}
.footer-logo-img {
  width: 100%;
  max-width: 250px;
  height: 150px;
  /* display: flex;
  justify-content: center; */
  padding-right: 35px;
  margin: 0 auto;
}
.footer-logo {
  width: 100%;
  object-fit: cover;
  height: 100%;
}
.footer-social-icons {
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
.footer-social-link {
  font-size: 30px;
  color: white;
  transition: color 0.3s;
}
@media (max-width: 768px) {
  .footer-logo-img {
    max-width: 200px;
    height: 200px;
    padding-right: 0;
  }

  .footer .col-md-4 {
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 20px;
    text-align: center;
  }

  .footer-link {
    font-size: 18px;
    padding: 10px;
  }

  .footer-social-icons {
    font-size: 1.5rem;
  }
}

@media (max-width: 480px) {
  .footer-logo-img {
    max-width: 150px;
    height: 100px;
    padding-right: 0;
  }

  .footer .row {
    flex-direction: column;
    align-items: center;
  }

  .footer .col-md-4 {
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 20px;
    text-align: center;
  }

  .footer-link {
    font-size: 12px;
    padding: 5px;
  }

  .footer-social-link {
    font-size: 1.2rem;
  }
}
